<template>
	<div class="layout-topbar">
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <router-link to="/" class="layout-topbar-logo">
			<span>Digitális munkalap</span>
		</router-link>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profilom</span>
				</button>
			</li>
            <li>
                <button class="p-link layout-topbar-button" @click="logOut">
                    <i class="pi pi-sign-out"></i>
                    <span>Kijelentkezés</span>
                </button>
            </li>
		</ul>
	</div>
</template>

<script setup>
import { defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth';

const router = useRouter()
const authStore = useAuthStore()
const emit = defineEmits(['menu-toggle'])


const onMenuToggle = (event) => {
  emit('menu-toggle', event);
}

const logOut = () => {
  authStore.logout()
  router.push('/')
}


</script>