<template>
	<div class="layout-menu-container">
		<app-submenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
	</div>
</template>

<script>
import AppSubmenu from '@/components/AppSubmenu';

export default {
	props: {
		model: Array
	},
  methods: {
    onMenuItemClick(event) {
        this.$emit('menuitem-click', event);
    },
    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === 'Enter' || event.code === 'Space') {
        nodeElement.click();
        event.preventDefault();
      }
    }
  },
	components: {
		AppSubmenu
	}
}
</script>