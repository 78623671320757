import { defineStore } from 'pinia'
import axios from 'axios'
import getEnvironment from '@/service/Environment'
import jwt_decode from 'jwt-decode'

const baseURL = getEnvironment().API_URL
const user = JSON.parse(localStorage.getItem('user')) || {}
const token = JSON.parse(localStorage.getItem('token')) || null
export const useAuthStore = defineStore('auth', {
    state: () => ({
        loggedIn: false,
        user,
        token
    }),
    actions: {
        async login(user) {
            const response = await axios.post('/login_check', { ...user }, { baseURL })
            if (response.data.token) {
                const token = response.data.token
                this.$patch({'user': jwt_decode(token)})
                this.$patch({'token': token})
                localStorage.setItem('user', JSON.stringify(jwt_decode(token)))
                localStorage.setItem('token', JSON.stringify(token))


                /// back compat
                const userData = await this.getData()
                localStorage.setItem('userData', JSON.stringify(userData.data))
                this.$patch({'user': userData.data})
                /////////////////////

                this.$patch({'loggedIn': true})
            }
        },
        logout() {
            localStorage.removeItem('user')
            localStorage.removeItem('userData')
            localStorage.removeItem('token')
            window.location.href = '/'
        },
        register(user) {
            return axios.post('/user/register', {
                name: user.name,
                email: user.email,
                password: user.password
            }, { baseURL });
        },
        getData() {
            return axios.get('/user-data', { baseURL })
        },
        hasRole(role) {
            return user.roles && user.roles.includes(`ROLE_${role}`)
        }
    }
})