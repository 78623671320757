export default function getEnvironment() {
    let APP_ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;
    let API_URL;
    
    if(APP_ENVIRONMENT === '0') {
        API_URL = process.env.VUE_APP_API_URL;
    } else {
        API_URL = process.env.VUE_APP_PROD_API_URL;
    }

    return {
        API_URL: API_URL
    };
}
