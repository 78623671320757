<template>
	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
          <AppMenu :model="state.menu" @menuitem-click="onMenuItemClick" />
        </div>

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="state.mobileMenuActive"></div>
        </transition>
	</div>
</template>

<script setup>
import { computed, onBeforeUpdate, reactive } from 'vue'

import AppTopBar from './components/AppTopbar.vue'
import AppMenu from './components/AppMenu.vue'
import AppFooter from './components/AppFooter.vue'

const state = reactive({
  menuClick: false,
  layoutMode: 'static',
  staticMenuInactive: false,
  overlayMenuActive: false,
  mobileMenuActive: false,
  menu : [
    {
      label: 'Munkalapok',
      items: [{
        label: 'Munkalapok', icon: 'pi pi-fw pi-book', to: '/worksheets'
      }]
    },
    {
      label: 'Felhasználók',
      items: [
        {
          label: 'Felhasználók',
          to: '/users',
          icon: 'pi pi-fw pi-user',
        },
        {
          label: 'Ügyfelek',
          to: '/clients',
          icon: 'pi pi-fw pi-users',
        },
      ]
    },
    {
      label: 'Adatok',
      items: [
        {
          label: 'Gépek',
          to: '/machines',
          icon: 'pi pi-fw pi-server',
        },
        {
          label: 'Perifériák',
          to: '/peripherals',
          icon: 'pi pi-fw pi-box',
        },
        {
          label: 'Árazás',
          to: '/product-prices',
          icon: 'pi pi-fw pi-money-bill',
        },
        {
          label: 'Eszközmozgások',
          to: '/peripheral-histories',
          icon: 'pi pi-fw pi-history',
        },
        {
          label: 'Szállítási területek',
          to: '/delivery-prices',
          icon: 'pi pi-fw pi-compass',
        },
        {
          label: 'Beállítások',
          to: '/settings',
          icon: 'pi pi-fw pi-cog',
        },
      ]
    }
  ]
})

const containerClass = computed(() => {
  return ['layout-wrapper', {
    'layout-overlay': state.layoutMode === 'overlay',
    'layout-static': state.layoutMode === 'static',
    'layout-static-sidebar-inactive': state.staticMenuInactive && state.layoutMode === 'static',
    'layout-overlay-sidebar-active': state.overlayMenuActive && state.layoutMode === 'overlay',
    'layout-mobile-sidebar-active': state.mobileMenuActive,
    // 'p-input-filled': this.$primevue.config.inputStyle === 'filled',
    // 'p-ripple-disabled': this.$primevue.config.ripple === false
  }]
})

const onWrapperClick = () => {
    if (!state.menuClick) {
        state.overlayMenuActive = false;
        state.mobileMenuActive = false;
    }

    state.menuClick = false;
}
const onMenuToggle = () => {
    state.menuClick = true;

    if (isDesktop()) {
        if (state.layoutMode === 'overlay') {
          if (state.mobileMenuActive === true) {
            state.overlayMenuActive = true;
          }

          state.overlayMenuActive = !state.overlayMenuActive;
          state.mobileMenuActive = false;
        }
        else if (state.layoutMode === 'static') {
          state.staticMenuInactive = !state.staticMenuInactive;
        }
    }
    else {
      state.mobileMenuActive = !state.mobileMenuActive;
    }
}
const onSidebarClick = () => {
    state.menuClick = true
}
const onMenuItemClick = (event) => {
    if (event.item && !event.item.items) {
        state.overlayMenuActive = false;
        state.mobileMenuActive = false;
    }
}
const addClass = (element, className) => {
    if (element.classList)
        element.classList.add(className);
    else
        element.className += ' ' + className;
}

const removeClass = (element, className) => {
    if (element.classList) {
      element.classList.remove(className)
    }
    else {
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
}

const isDesktop = () => {
  return window.innerWidth >= 992;
}

onBeforeUpdate(() => {
    if (state.mobileMenuActive)
        addClass(document.body, 'body-overflow-hidden');
    else
        removeClass(document.body, 'body-overflow-hidden');
})

</script>